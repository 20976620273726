<template>
  <div class="container fluid">
    <div class="row mb-3">
      <div class="col-md-4">
        <input
          type="text"
          v-model="search"
          @input="searchPosts"
          class="form-control"
          placeholder="Search posts..."
        />
      </div>

      <div class="col-auto ms-auto">
        <router-link
          class="btn btn btn-outline-success btn-sm"
          to="/posts/create"
        >
          <i class="far fa-plus me-2"></i>Add New
        </router-link>
      </div>
    </div>
    <div class="card border shadow-none" v-if="!busy">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Author</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cursor-pointer" v-for="p in posts" :key="p.id">
                    <td @click="viewPost(p)">{{ p.title }}</td>
                    <td @click="viewPost(p)">
                      <span v-if="p.author">{{ p.author.first_name + ' ' + p.author.last_name }}</span>
                      <span v-else>-</span>
                      </td>
                    <td @click="viewPost(p)">{{ p.status }}</td>
                    <td @click="viewPost(p)">
                      {{ p.created_at | formatDate }}
                    </td>
                    <td>
                      <button
                        class="btn btn-outline-danger btn-sm"
                        @click="deletePost(p)"
                      >
                        <i class="far fa-times"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="pagination">
              <div class="left">
                <button
                  class="btn btn-sm btn-outline-success cursor-pointer"
                    @click="postsFirst" :disabled="!this.firstPage || this.currentPageNr === 1"
                >First Page</button>
                <button
                  class="btn btn-sm btn-outline-success cursor-pointer"
                    @click="postsPrev" :disabled="!this.prevPage"
                >Previous</button>
              </div>

              <div class="middle">
                Page {{ this.currentPageNr }} of
              {{ this.lastPageNr }}
              </div>

              <div class="right">
                <button
                  class="btn btn-sm btn-outline-success cursor-pointer"
                    @click="postsNext" :disabled="!this.nextPage"
                >Next</button>
                <button
                  class="btn btn-sm btn-outline-success cursor-pointer"
                    @click="postsLast" :disabled="!this.lastPage || this.currentPageNr === this.lastPageNr"
                >Last Page</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading v-else></loading>
  </div>
</template>

<script>
import debounce from "debounce";
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      posts: [],
      busy: false,
      search: null,
      firstPage: null,
      prevPage: null,
      currentPageNr: 1,
      lastPageNr: 1,
      nextPage: null,
      lastPage: null
    };
  },
  methods: {
    viewPost(post) {
      this.$router.push("/posts/" + post.id);
    },

    fetchPosts(e, url) {
      this.busy = true;
      this.$axios
        .get(url ? url : process.env.VUE_APP_API_URL + "/posts")
        .then(({ data }) => {
          this.posts = data.data;
          this.handlePagination(data);

          this.busy = false;
        });
    },

    searchPosts: debounce( function(e, url) {
      this.busy = true;
      this.$axios
        .post(url ? url : process.env.VUE_APP_API_URL + "/posts/search", {
          search: this.search,
        })
        .then(({ data }) => {
          this.posts = data.data;
          this.handlePagination(data);

          this.busy = false;
        });
    }),

    handlePagination(data) {
      this.firstPage = data.first_page_url;
      this.prevPage = data.prev_page_url;
      this.lastPageNr = data.last_page;
      this.nextPage = data.next_page_url;
      this.lastPage = data.last_page_url;
    },

    postsFirst(e) {
      if (this.firstPage) {
        this.currentPageNr = 1;
        this.fetchPosts(e, this.firstPage);
      }
    },

    postsPrev(e) {
      if (this.prevPage && this.currentPageNr > 1) {
        this.currentPageNr = this.currentPageNr-1;
        this.fetchPosts(e, this.prevPage);
      }
    },

    postsNext(e) {
      if (this.nextPage && this.currentPageNr < this.lastPageNr) {
        this.currentPageNr = this.currentPageNr+1;
        this.fetchPosts(e, this.nextPage);
      }
    },

    postsLast(e) {
      if (this.lastPage) {
        this.currentPageNr = this.lastPageNr;
        this.fetchPosts(e, this.lastPage);
      }
    },

    deletePost(post) {
      if (
        confirm(
          "Are you sure you wish to delete this post, this action cannot be undone?"
        )
      ) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/posts/" + post.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchPosts();
          });
      }
    },
  },
  created() {
    this.fetchPosts();
  },
  components: {
    Loading,
  },
};
</script>

<style>
  .pagination {
    display: flex;
    justify-content: space-between;
  }

  .pagination button {
    margin: 0 0.5rem;
  }
</style>